import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Table,
    FormSelect,
    Alert,
    Toast,
    Modal
} from 'react-bootstrap'

import Swal, { SweetAlertIcon } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

import './findUserFull.sass'

import {
  BsCheck,
  BsCheckLg,
  BsX,
  BsExclamationLg,
} from 'react-icons/bs'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'

import './findGameUser.sass'

const isUserCreated = true;

const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export function FindGameUser() {

    const [userList, setUserList] = useState([])

    const [isLoading, setLoading] = useState(false)

    const [resetedBio, setResetedBio] = useState(false)

    const [userSearch, setUserSearch] = useState({
        identifier: '',
        type: 'document'
    })

    const currentUserRole = sessionStorage.getItem('role') || 'na'

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const onSubmit = (data: any) => {

        setLoading(true)
        fetch(`${BASE.api.base_url}${BASE.api.find_user_with_biometry}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': CONFIG.API_KEY_1
            },
            body: JSON.stringify({
                userId: data.identifier.replace(/-/g, '').replace(/\./g, ''),
                companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
                userSearchType: 'document',
                userType: 'game'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setResetedBio(false)
                setUserList(response.data)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const removeBiometry = (userId: any, userData: any) => {


        setLoading(true)
        setResetedBio(false)
        if (!confirm('Tem certeza?')) {
            setLoading(false)
            return false
        }

        fetch(`${BASE.api.base_url}${BASE.api.remove_biometry}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                user: userId,
                document: userData[1]
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setResetedBio(true)
                generateLog({ affectationId: userId })
                onSubmit({
                    type: userData[0],
                    identifier: userData[1]
                })
                window.location.reload()
            })
            .catch((error) => {
                setLoading(false)
                setResetedBio(false)
                console.log(error)
            })
    }

    const fixName = (userToken: any, userName: any) => {
        setLoading(true)
        if (!confirm('Tem certeza?')) {
            setLoading(false)
            return false
        }
        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_edit}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                id: userToken,
                name: `${userName}_`
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                alert('Usuário Alterado.')
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const generateLog = (data: any) => {
        const requestData = {
            userId: sessionStorage.getItem('token'),
            detail: {
                rule: sessionStorage.getItem('role'),
                process: 'biometry delete for game'
            },
            affectation: data.affectationId
        }
        fetch(`${BASE.api.base_url}${BASE.api.log_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(requestData)
        })
    }


    const [shotToast, setShotToast] = useState(true);

    const toggleToas = () => setShotToast(!shotToast);

    const copyLink = (link: any) => {

        toggleToas()
        navigator.clipboard.writeText(link);


    }

    const dateFormatter = (dateTime:string)=>{

      const options:any = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

      const oldDate = new Date(dateTime)
      return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
    }


    const [onboardingLinkText, setOnboardinLinkText] = useState('Link Onboarding')
    const getOnboardingLink = (token:any) => {

      navigator.clipboard.writeText(`https://santos.bepass.com.br/?token=${token}`)

      setOnboardinLinkText('Link Copiado!')
      setTimeout(() => {
        setOnboardinLinkText('Link Onboarding')
      }, 2000);
      return
    }

    const initialUserState = {
        access_code: null,
        biometric: true,
        biometric_create: '',
        birthDate: '',
        document: '',
        userAvatar: '',
        docFront: '',
        docBack: '',
        documentValidation: '',
        document_approved: '',
        email: '',
        externalId: '',
        firstName: '',
        hikId: '',
        id: '',
        name: ''
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const [isBiometricsModalOpen, setBiometricsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(initialUserState);
    const [loading, setLoadingBiometrics] = useState(false);
    const [biometricList,setBiometricsList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const openModal = (user : any) => {
        setSelectedUser(user);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedUser(initialUserState);
    };

  const openBiometricsModal = async (user : any, page: number) => {
    setSelectedUser(user);
    setBiometricsModalOpen(true);
  };

  const closeBiometricsModal = () => {
    setBiometricsModalOpen(false);
    setBiometricsList([])
    setCurrentPage(1)
  };



  const showCustomAlert = (informationType: SweetAlertIcon, message: string, title: string) => {
    const iconType: SweetAlertIcon[] = ['success', 'error', 'warning', 'info', 'question']
    const icon = iconType.find((icon) => icon === informationType)
    Swal.fire({
      icon: icon,
      title: title,
      text: message,
    });
  }

  const onPageChange = async (page: number) => {
    setCurrentPage(page);
    setBiometricsList([])
  }

    const forceApproval = (userToken: any) => {

      if(!confirm('Tem certeza?')){
        return true
      }
      const requestData = {
          token: userToken.token,
          biometry: userToken.biometryId,
          operator:  sessionStorage.getItem('token'),
      }
      fetch(`${BASE.api_user.base_url}${BASE.api_user.force_update}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: CONFIG.API_KEY
          },
          body: JSON.stringify(requestData)
      })

      MySwal.fire({
        title: 'Aprovado!',
        text: 'O usuário foi atualizado com sucesso.',
        icon:'success',
        confirmButtonColor: '#006f3c',
      } )

      setModalOpen(false)
  }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5" className="search-header">
                        Pesquisar Torcedor
                    </Card.Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="mb-2" lg={12} xl={8}>
                                <input
                                    placeholder="CPF/Passaporte"
                                    className="form-control"
                                    {...register('identifier', { required: true })}
                                />
                            </Col>
                            <Col className="mb-2" lg={12} xl={4}>
                                <input
                                    className="btn w-100 btn-primary"
                                    type="submit"
                                    value="Pesquisar"
                                    onClick={() => clearErrors()}
                                />
                            </Col>
                        </Row>
                    </form>
                </Card.Header>

                <Card.Body>
                    {isLoading &&
                        <div className='local-loader'>
                            <i
                                className="fa fa-spinner fa-spin text-c-red f-10 m-r-15"
                                title="oiee"
                            />
                        </div>}
                    <Table responsive>
                        <tbody>
                            {userList &&
                                userList.map((user: any) => (
                                    <>
                                    <tr>
                                      <td colSpan={2}>
                                        <ul className="ul">
                                          <li className='li'>
                                            <div className={isUserCreated ? 'dot green after' : 'dot after red'}>
                                              {isUserCreated ? <BsCheckLg size={25} color="#000"/> : <BsExclamationLg size={25} color="#000"/>}
                                            </div>
                                            Usuário
                                            <br/>
                                            Criado
                                          </li>
                                          <li className='li'>
                                            <div className={user.biometric ? 'dot green after' : 'dot red after'}>
                                              {user.biometric ? <BsCheckLg size={25} color="#000"/> : <BsExclamationLg size={25} color="#000"/>}
                                            </div>
                                            Biometria
                                            <br/>
                                            Facial
                                            <br/>
                                            {user.biometric && (<span>{dateFormatter(user.biometric_create)}</span>)}
                                          </li>
                                          <li className='li'>
                                            { user.documentValidation === 'approved' && (
                                              <>
                                              <div className='dot green after' >
                                                <BsCheckLg size={25} color="#000"/>
                                              </div>
                                              Documentos
                                              <br/>
                                              Validados
                                              <br/>
                                              {user.biometric && (<span>{dateFormatter(user.document_approved)}</span>)}
                                              </>
                                            )}
                                             { user.documentValidation !== 'approved' && (
                                              <>
                                              <div className='dot red after' >
                                                <BsExclamationLg size={25} color="#000"/>
                                              </div>
                                              Documentos
                                              <br/>
                                              Validados
                                              </>
                                            )}
                                          </li>
                                          <li className='li'>
                                            { user.documentValidation === 'approved' && (
                                              <>
                                              <div className='dot green' >
                                                <BsCheckLg size={25} color="#000"/>
                                              </div>
                                              Cadastro
                                              <br/>
                                              Completo
                                              </>
                                            )}
                                            { user.documentValidation !== 'approved' && (
                                              <>
                                              <div className='dot red' >
                                                <BsExclamationLg size={25} color="#000"/>
                                              </div>
                                              Cadastro
                                              <br/>
                                              Completo
                                              </>
                                            )}
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                        <tr key={user.hikiId} className="">
                                            <td>
                                              {user.userAvatar != null &&     <img className='center' width={200} src={`data:image/png;base64,${user.userAvatar }`} />}
                                              {user.userAvatar == null  &&     <img className='center' width={200} src={`placeholder-person.png`} />}
                                            </td>
                                            <td>
                                                <p className='user-data-game'>
                                                    <span>Nome:</span> {user.firstName} {user.name} <br />
                                                    <span>Documento:</span> {maskCPF(user.document)} <br />
                                                    <span>E-mail:</span> {user.email} <br />
                                                    <span>Data Nasc.:</span> {`${user.birthDate.split('-')[2]}/${user.birthDate.split('-')[1]}/${user.birthDate.split('-')[0]}`}
                                                </p>

                                                <hr />
                                                {(currentUserRole !== 'default') && (
                                                <button
                                                className={`label user-trigger btn-ghost f-12`}
                                                onClick={() => {
                                                    removeBiometry(user.token, getValues(['type', 'identifier']));
                                                }}
                                                >
                                                Refazer Biometria
                                            </button>
                                                )}


                                                <br/>
                                                {(currentUserRole === 'bedimin' || currentUserRole === 'admin' || currentUserRole === 'supervisingEditor') && (
                                                <>
                                                <button
                                                    className={`label user-trigger btn-ghost-delete text-black f-12`}
                                                    onClick={() => getOnboardingLink(user.token)}
                                                    >
                                                    {onboardingLinkText}
                                                </button>

                                                </>
                                                )}
                                            {(currentUserRole === 'bedimin') && (
                                              <>
                                                <br />
                                                <button
                                                  className={`label user-trigger btn-ghost-delete text-black f-12`}
                                                  onClick={() => openModal(user)}
                                                >
                                                  Aprovação Manual
                                                </button>
                                              </>
                                            )}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
              {userList &&
                userList.map((user: any) => (
                <>
                  {user.syncStatus && user.syncStatus.length > 0 && (
               <Card.Footer className='sync-footer'>
                <h6>sincronização: <span>{user.token}</span></h6>
                <ul className='sync-devices'>
                { user.syncStatus.sort((a: any, b: any) => {
  const lastOctetA = Number(a.device_name.split('-')[1].slice(0,2));
  const lastOctetB = Number(b.device_name.split('-')[1].slice(0,2));
  return  lastOctetA - lastOctetB;
})
.map((item: any, index: any) => (

                  <li className={item.sync_isFaceCreated} key={index}>{item.device_name}</li>
                ))}
                </ul>
                </Card.Footer>
                )}
                  </>

                                ))}


            </Card>

            <Modal show={isModalOpen} onHide={closeModal} size="lg" className="modal-container">
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title>Aprovação Manual</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <>
                            <div className="grid-container">
                                <div className="column">
                                    <div className="info-header">
                                        {selectedUser.userAvatar ? (
                                            <img
                                                className="avatar"
                                                src={`data:image/png;base64,${selectedUser.userAvatar}`}
                                                alt="Avatar do Usuário"
                                            />
                                        ) : (
                                            <img
                                                className="avatar"
                                                src="placeholder-person.png"
                                                alt="Avatar Padrão"
                                            />
                                        )}
                                    </div>

                                    <div className="image-container">
                                        {selectedUser.docFront ? (
                                            <img
                                                className="document"
                                                src={`data:image/png;base64,${selectedUser.docFront}`}
                                                alt="Frente do Documento"
                                            />
                                        ) : (
                                            <p>Imagem da frente do documento não disponível.</p>
                                        )}
                                        <p><strong>Frente do Documento</strong></p>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="info-header">
                                        <p><strong>Nome:</strong> {selectedUser.firstName} {selectedUser.name}</p>
                                        <p><strong>Documento:</strong> {maskCPF(selectedUser.document)}</p>
                                        <p><strong>Data de Nascimento:</strong> {selectedUser.birthDate.split('-').reverse().join('/')}</p>
                                    </div>

                                    <div className="image-container">
                                        {selectedUser.docBack ? (
                                            <img
                                                className="document"
                                                src={`data:image/png;base64,${selectedUser.docBack}`}
                                                alt="Verso do Documento"
                                            />
                                        ) : (
                                            <p>Imagem do verso do documento não disponível.</p>
                                        )}
                                        <p><strong>Verso do Documento</strong></p>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => forceApproval(selectedUser)}>
                        Forçar Aprovação
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
